.mainContainer {
  .topTriangle {
    top: 0;
    border-right: 29px solid transparent;
    position: absolute;
    display: block;
    left: 0;
    width: 0;
    height: 0;

    &.active {
      border-top: 30px solid #2185d0;
    }

    &.inActive {
      border-top: 30px solid #767676;
    }

    &.created {
      border-top: 30px solid #21ba45;
    }

    .hoverTitleContainer {
      visibility: hidden;
      width: 70px;
      background-color: black;
      opacity: 0.8;
      color: #fff;
      text-align: center;
      font-size: 16px;
      border-radius: 6px;
      padding: 5px 0;

      position: absolute;
      z-index: 999;
      top: 90%;
      left: 50px;
      margin-left: -60px;
    }
  }

  &:hover .hoverTitleContainer {
    visibility: visible;
    z-index: 999;
  }
}

.topLogoImage {
  top: 2px;
  left: 1px;
  width: 15px;
  height: 15px;
  position: absolute !important;
  z-index: 1;
}
