@import "./styles/colors.scss";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $rpm-gunmetal-tint3;
}

iframe {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:global {
  .Toastify__toast--info {
    background-color: $rpm-detroit-blue-tint2 !important;
    color: #000 !important;
  }

  .Toastify__toast--error {
    background-color: $wrong-red !important;
  }

  .Toastify__progress-bar--info {
    background: rgba(34, 36, 38, 0.45) !important;
  }

  .Toastify__toast--info button {
    color: #000;
  }

  .ui.selection.dropdown:focus,
  .ui.selection.active.dropdown:hover,
  .ui.selection.active.dropdown,
  .ui.input.focus > input,
  .ui.input > input:focus {
    border: 0px 0px 2px 0px;
    border-color: rgb(61, 211, 216) !important;
    transition: 0.2s ease all !important;
  }

  .ui.selection.active.dropdown .menu {
    border-color: rgb(61, 211, 216) !important;
    border: 1px solid rgb(61, 211, 216) !important;
  }

  .ui.checkbox input:focus ~ .box:before,
  .ui.checkbox input:focus ~ label:before {
    border-color: rgb(61, 211, 216) !important;
    background: rgba(0, 0, 0, 0.07);
  }
}

.app-container-loader {
  position: relative;
  width: 100%;
}
