@use "../../mixins.scss" as mixins;
@import "../../styles/colors.scss";

.carrierCapacity {
  flex: 1 1 auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #22242626;
  padding: 8px;

  header {
    display: flex;
    align-items: center;
    gap: 10px;

    h2 {
      font-size: 1.25rem;
      margin-bottom: 0px;
      width: 120px;
    }

    button {
      padding: 5px 10px !important;
      border-radius: 40px !important;
    }
  }

  table {
    thead {
      font-size: 1rem;

      tr {
        th {
          padding: 8px 10px !important;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 8px 10px !important;
          max-width: 250px;
          @include mixins.text-truncate;

          &:first-child {
            width: 40px;
          }

          &:last-child {
            width: 122px;
          }

          :global div.line {
            max-height: 6px;
          }

          button {
            padding: 3px 6px !important;
            border-radius: 40px !important;
          }
        }
      }
    }
  }

  > div {
    overflow-x: auto;

    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
}
