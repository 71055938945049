@use "../../../mixins.scss" as mixins;

.carrierFilterBar {
  max-width: 100vw;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 5px 38px;
  background-color: white;

  .filterTitle {
    font-weight: 700;
  }

  .labelsContainer {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    .labelTag {
      @include mixins.preferred-button-style-of-profiles-jesus-joshua-trantham;
      white-space: nowrap;
      margin: 0;
    }
  }

  .carrierFilterSection {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
