.carriersContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;

  .carriersSearchBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 38px;
    background-color: white;
    margin-bottom: 1px;
  }
}

.carrierMinifiedListContainer {
  z-index: 0;
  display: grid;
  width: 100%;
  overflow: hidden;
  grid-template-areas: "list capacity";
  grid-template-rows: 1fr;
  grid-template-columns: minmax(640px, 1.2fr) calc(100% - 640px);
  flex: 1;
}

.listArea {
  grid-area: list;
  overflow: hidden;
}

.capacityArea {
  grid-area: capacity;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .capacityAreaHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: 1px solid rgba(34, 36, 38, 0.2);
    height: 42px;
    padding: 8px;

    h1 {
      font-size: 1.4rem;
      margin: 0px;
    }

    i {
      margin: 0;
    }
  }
}
