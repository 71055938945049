.search_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 350px;
  height: 30px;

  input {
    margin: 0;
    flex: 1 0 auto;
    background-color: rgba(246, 246, 246, 0.91);
    border-radius: 15px 0 0 15px;
    border: 1px solid #c9c9c9;
    font: 400 13.3333px Arial, sans-serif;
    line-height: 1em;
    writing-mode: horizontal-tb !important;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    display: inline-block;
    padding: 0 10px;

    &:focus {
      outline: #989898;
      border-color: #989898 !important;
      transition: 0.2s ease all !important;
    }
  }

  .icon_wrapper, .icon_wrapper_auto_search {
    background-color: rgba(246, 246, 246, 0.91);
    padding: 5px 8px 0 10px;
    height: 30px;
    border-radius: 0 15px 15px 0;
    border: 1px solid #c9c9c9;
    border-left: none;
    cursor: pointer;

    &:hover {
      background-color: #d3d2d2;
      transition-duration: 0.3s;
    }
  }

  .focus_input {
    border: 1px solid #989898;
    border-left: none;
  }

  .icon_wrapper_auto_search {
    cursor: default;

    &:hover {
      background-color: rgba(246, 246, 246, 0.91);
    }
  }

  .default_cursor {
    cursor: default !important;
  }
}