.NotificationToast {
    button {
        opacity: 1;
    }
}

.NotificationHeader {
    color: white !important;
}

.NotificationProgressBar {
    background: white;
}

.WrapErrorList {
    white-space: pre-wrap;
}