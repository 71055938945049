@use "../../../mixins.scss" as mixins;
@import "../../../styles/colors.scss";

.popupContainer {
  .filterHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4px;

    h2 {
      font-size: 1.3rem;
      margin-bottom: 0;
    }
  }

  .scrollContainer {
    max-height: 75vh;
    min-width: 550px;
    display: flex;
    flex-direction: column;
    padding: 0.6em;
    gap: 10px;
    overflow-y: scroll;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;

    .header {
      color: gray;
      font-weight: 700;
      padding-bottom: 4px;
      margin-bottom: 10px;
      border-bottom: 1px solid lightgray;
    }

    .inlineDropdowns {
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 6px !important;

        > label {
          flex: 100px 0;
        }

        > div {
          flex: 1 1;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  .filterFooter {
    text-align: right;
    margin-top: 0.6em;

    button {
      @include mixins.preferred-button-style-of-profiles-jesus-joshua-trantham;
    }
  }
}
