@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin preferred-button-style-of-profiles-jesus-joshua-trantham {
  padding: 5px 10px !important;
  border-radius: 40px;
}

@mixin table-header-style {
  background-color: rgb(14, 30, 57);
  text-align: start;
  color: white;
}
