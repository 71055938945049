.container {
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  .error {
    font-size: 20rem;
    line-height: 20rem;

    @media (max-width: 768px) {
      font-size: 13rem;
      line-height: 13rem;
    }
  }

  .title {
    font-size: 3rem;
    font-style: italic;
    margin-top: 0;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .text {
    font-size: 1.5rem;
    margin: 0 0 20px;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
}