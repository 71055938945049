.breadcrumbsContainer {
  padding-top: 4px;
  padding-bottom: 4px;
  color: rgba(0, 0, 0, 0.7) !important;
  font-size: 14px;
  > a {
    color: rgba(0, 0, 0, 0.7) !important;
  }
}

.divider {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

button.breadcrumbsLink {
  color: #4183c4;
  cursor: pointer;
  font-weight: 500;
  background: none;
  border: none;
  padding: 0px;

  &:hover {
    text-decoration: underline;
  }
}
