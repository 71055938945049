.carrierPlanCapacityForm {
  label:has(+ div > input:required, + div[required])::after {
    content: " *";
    color: red;
  }

  // Semantic is not properly showing input error styling so re-add it with higher specificity
  :global(div.ui.error.input) input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none;
  }

  :global(.ui.disabled.input) {
    opacity: 1;
  }

  h4 {
    margin-top: 0.875rem;
  }
}
