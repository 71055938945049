.pageHeader {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: .3rem 75px;
  height: 45px;
}

.searchBarArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -3px;
}

.filterSection {
  text-align: right;
  display: flex;
}

.searchInput {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  background: #fff;
  color: rgba(0,0,0,.87);
  transition: box-shadow .1s ease,border-color .1s ease;
  box-shadow: none;
  border: 0 solid rgba(34, 36, 38, .35);
  border-bottom-width: 1px;
  font: 400 13.3333px Arial, sans-serif;
  line-height: 1em;
  writing-mode: horizontal-tb !important;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  &:focus {
    border-color: #3dd3d8 !important;
    transition: 0.2s ease all !important;
  }
}

.searchContainer {
  min-width: 35%;
  font-size: 1em;
  position: relative;
  font-weight: 400;
  font-style: normal;
  display: inline-flex;
  color: rgba(0,0,0,.87);
  height: 35px;
}

.header {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

@media (max-width: 950px) {
  .pageHeader {
    padding: .3rem 25px;
  }
}